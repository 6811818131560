import React from 'react';
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import CoverImage from '../../components/CoverImage';
import signUpMobile from '../../../assets/signupMobile.png';
import signUpDesktop from '../../../assets/signUpDesktop.png';
import NavBarMenu from '../../components/NavBarMenu';
import SingleAdviceCard from '../../components/SingleAdviceCard';

const SingleAdvicePage = ({ data, pageContext, t }) => {
  const initMetas = [
    {
      name: 'description',
      content: (data?.currentAdvice?.description?.description).replace(/\n/g, ' '),
    },
    {
      name: 'og:title',
      content: data?.currentAdvice?.title || "Zawema",
    },
    {
      name: 'og:description',
      content: (data?.currentAdvice?.description?.description).replace(/\n/g, ' '),
    },
    {
      name: 'og:url',
      content: `${process.env.GATSBY_WEBSITE_URL}/${pageContext.locale}/advice/${pageContext.slug}`,
    },
    {
      name: 'og:type',
      content: "article",
    },
    {
      name: 'article:author',
      content: data?.currentAdvice?.author || "Zawema",
    },
    {
      name: 'article:published_time',
      content: data?.currentAdvice?.date || "2022-01-01",
    },
  ];

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.slug}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>{data?.currentAdvice?.title} | Zawema</title>
        {/* Seo and OpenGraph */}
        {initMetas && initMetas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <>
        <CoverImage
          imageDesktop={signUpDesktop}
          imageMobile={signUpMobile}
          title="Blog"
          altDesktop="Conseils Zawema"
          altMobile="Conseils Zawema"
        />
      </>
      <div className="max-w-screen-xl mx-auto px-2 sm:px-10 mt-5 mb-20">
        <SingleAdviceCard
          image={data.currentAdvice.image.file.url}
          desc={data.currentAdvice.description.description}
          title={data.currentAdvice.title}
          text={data.currentAdvice.text}
          date={data.currentAdvice.date}
          author={data.currentAdvice.author}
          lang={pageContext.locale}
        />
      </div>
    </Layout>
  );
};

export default SingleAdvicePage

export const query = graphql`
    query($slug: String!, $locale: String!) {
        menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
            edges {
              node {
                slug
                categoryName
              }
            }
          }
        menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
        sort : {fields: [slug], order: ASC}) {
            edges {
              node {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                } 
              }
            }
          }
        allContentfulCategorySve(filter: { node_locale: { eq: $locale } }) { 
            edges {
              node {
                categoryName
                categoryEncartTitle
                slug
                parentCategory {
                  categoryName
                  categoryEncartTitle
                }
              }
            }
          }
        currentAdvice: contentfulAdviceCard(slug: {eq: $slug}, node_locale: {eq: $locale}) {
          image {
            file {
              url
            }
            description
          }
          title
          description {
            description
          }
          text {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                title
                description
                gatsbyImageData(width: 300)
                __typename
              }
            }
          }
          date
          author
          slug
        }
    }
`
