import React from "react";
import PropTypes from "prop-types";

const ThirdLevelItems = ({ parentSlug, parentParentSlug, slug, categoryName }) => (
  <li
    key="FirstLevelCateg"
    className="text-grey-light text-s py-1 outline-none focus:font-semibold w-full relative"
  >
    <a href={`/fr/produits/${parentParentSlug}/${parentSlug}/${slug}`}
      className="text-grey-light text-s focus:font-semibold w-full capitalize"
    >
      {categoryName}
    </a>
  </li>
);

export default ThirdLevelItems;

ThirdLevelItems.propTypes = {
  slug: PropTypes.string,
  parentParentSlug: PropTypes.string,
  parentSlug: PropTypes.string,
  categoryName: PropTypes.string
};
