import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";

const FirstLevelItems = ({ slug, categoryName, dropdown, slugLocale }) => {

  const [isShowing, setIsShowing] = useState(false);

  return (
    <li
      key="FirstLevelCateg"
      className="text-grey-light text-s font-medium outline-none px-4 md:px-6 2xl:px-16 pr-2 lg:pr-18"
      onMouseEnter={() => setIsShowing(true)}
      onMouseLeave={() => setIsShowing(false)}
    >
      <a
        href={`/${slugLocale}/produits/${slug}`}
        className="text-grey-light text-s hover:font-semibold hover:text-orange-dark pb-2"
      >
        {categoryName}
      </a>
      <Transition
        as={Fragment}
        show={isShowing}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-500 translate-y-1"
      >
        {dropdown}
      </Transition>
    </li>
  );
};

export default FirstLevelItems;

FirstLevelItems.propTypes = {
  slug: PropTypes.string,
  categoryName: PropTypes.string
};