
const getStyles = (theme) => ({
  list: {
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-end",
    width: "20%",
  },
  text: {
    // width: "700%",
  }
});

export default getStyles;