import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";
import FirstLevelItems from './FirstLevelItems';
import SecondLevelItems from './SecondLevelItems';
import ThirdLevelItems from './ThirdLevelItems';

const NavBarMenu = ({
  firstLevelCategories,
  secondLevelCategories,
  thirdLevelCategories,
  lang
}) => {

  const classes = useStyles(getStyles());

  return (
    <div className="max-w-full px-2 sm:px-4 mb-2 bg-white z-50 hidden md:block">
      <nav className="bg-white">
        <div className="bg-white">
          <div className="flex items-center justify-center h-16 bg-white px-24">
            <div className="flex bg-white">
              <ul className="static flex bg-white z-50" style={classes.firstRow}>
                {firstLevelCategories?.map(({ node: firstLevelCategory }) => (
                  <FirstLevelItems
                    slug={firstLevelCategory.slug}
                    categoryName={firstLevelCategory.categoryName}
                    slugLocale={lang}
                    dropdown={
                      <ul className="flex flex-wrap absolute left-0 bg-white w-full lg:pt-6 pb-8 px-4 py-8 lg:px-64">
                        {secondLevelCategories?.map(({ node: secondLevelCategory }) => secondLevelCategory.parentCategory.slug === firstLevelCategory.slug && (
                          <SecondLevelItems
                            slug={secondLevelCategory.slug}
                            parentSlug={secondLevelCategory.parentCategory.slug}
                            categoryName={secondLevelCategory.categoryEncartTitle ? secondLevelCategory.categoryEncartTitle : secondLevelCategory.categoryName}
                            dropdown={
                              thirdLevelCategories?.map(({ node: thirdLevelCategory }) => thirdLevelCategory.parentCategory.slug === secondLevelCategory.slug && (
                                <ThirdLevelItems
                                  slug={thirdLevelCategory.slug}
                                  parentSlug={secondLevelCategory.slug}
                                  parentParentSlug={secondLevelCategory.parentCategory.slug}
                                  categoryName={thirdLevelCategory.categoryEncartTitle ? thirdLevelCategory.categoryEncartTitle : thirdLevelCategory.categoryName}
                                />
                              ))
                            }
                          />
                        ))}
                      </ul>
                    }
                  />
                ))}
                {/* Menu section linking to CMS pages */}
                {/* <li className="px-4 md:px-6 2xl:px-16 font-medium pr-4 lg:pr-18 bl-2 text-s">
                  <Link to="/coaching" className="text-grey-light text-s hover:font-semibold hover:text-orange-dark pb-2" rel="noopener noreferrer">
                    Coaching
                  </Link>
                </li> */}
                <li className="px-4 md:px-6 2xl:px-16 font-medium pr-4 lg:pr-18 bl-2 text-s">
                  <Link to={`/${lang}/advice`} className="text-grey-light text-s hover:font-semibold hover:text-orange-dark pb-2" rel="noopener noreferrer">
                    Blog
                  </Link>
                </li>
                <li className="px-4 md:px-6 2xl:px-16 font-medium pr-4 lg:pr-18 bl-2 text-s">
                  <Link to={`/${lang}/salons`} className="text-grey-light text-s hover:font-semibold hover:text-orange-dark pb-2" rel="noopener noreferrer">
                    Salons
                  </Link>
                </li>
                {/* for mobile view */}
                <li className="md:hidden">
                  <Link to="/mon-compte">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-8 w-8 text-grey-light hover:text-grey-dark"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-s text-grey">Mon compte</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default NavBarMenu;

NavBarMenu.propTypes = {
  firstLevelCategories: PropTypes.array,
  secondLevelCategories: PropTypes.array,
  thirdLevelCategories: PropTypes.array,
};