import React from 'react';
import { Link } from 'gatsby';
import { formatTimestampToDateWithDashes } from '../../utils/format';
import { formatDateToFrenchFormat } from '../../utils/format';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import videoThumbnail from '../../../assets/video-thumbnail.png'

const SingleAdviceCard = ({ image, title, text, date, author, desc, lang }) => {

  const options = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="mt-2 text-s md:text-sm">{children}</h2>,
      [BLOCKS.PARAGRAPH]: (node, children) => <div className="text-xs md:text-s md:leading-5 py-2 md:py-3">{children}</div>,
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { gatsbyImageData, description } = node.data.target
        return (
          <div className="flex justify-center py-2" >
            <GatsbyImage
              image={getImage(gatsbyImageData)}
              placeholder="blurred"
              alt={description}
            />
          </div>
        )
      },
    },
    renderMark: {
      [MARKS.CODE]: code =>
        <iframe
          srcdoc={`
              <style>
                body, .full {
                  width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
                    }
              </style>
              <a
                href=${code}
                class='full'
              >
                <img
                  src=${videoThumbnail}
                  alt="Thumbnail video"
                  class='full'
                />
                <svg
                  version='1.1'
                  viewBox='0 0 68 48'
                  width='68px'
                  style='position: relative;'
                >
                  <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                  <path d='M 45,24 27,14 27,34' fill='#fff'></path>
                </svg>
              </a>
              `}
          width="464"
          height="383"
          className="w-full px-4"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        ></iframe>
    }
  }

  return (
    <div class="px-2 py-10 md:px-10">
      <div class="w-full rounded overflow-hidden shadow-lg">
        <img class="w-auto md:h-80 h-44 mx-auto" src={image} alt={desc}></img>
        <div class="px-6 py-4">
          <div class="font-bold text-base md:text-lg text-center py-4">
            {title}
          </div>
          <p class="text-gray-700 md:px-10 px-2 md:text-base text-s">
            {renderRichText(text, options)}
          </p>
        </div>
        <div className="py-2">
          <div className="ml-3">
            <div className='flex pr-6 items-center justify-end underline border-b border-gray-200'>
              <svg class="h-6 w-6 text-black" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <polyline points="15 18 9 12 15 6" />
              </svg>
              <p className="text-s font-medium py-4">
                <Link to={`/${lang}/advice`} className=" text-s px-2 pr-16 py-5 text-gray-700 font-semibold">
                  Retour aux articles
                </Link>
              </p>
            </div>
            <div className="flex items-center justify-around space-x-1 h-20 py-5 pb-4 text-xs text-grey-lightDark">
              <span className="dateTime">
                {formatDateToFrenchFormat(formatTimestampToDateWithDashes(date))}
              </span>
              <span aria-hidden="true"> &middot; </span>
              <span> {author} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default SingleAdviceCard;