import React from "react";
import PropTypes from "prop-types";
import { useStyles } from "react-styles-hook";
import getStyles from "./styles";

const SecondLevelItems = ({ parentSlug, slug, categoryName, dropdown }) => {
  const classes = useStyles(getStyles());

  return (
    <li
      key="FirstLevelCateg"
      className="text-grey-darkest pb-4 text-s outline-none font-medium"
      style={classes.list}
    >
      <a href={`/fr/produits/${parentSlug}/${slug}`}
        className="text-grey-darkest text-s font-medium capitalize whitespace-normal"
        style={classes.text}
      >
        {categoryName}
      </a>
      <div>
        {dropdown}
      </div>
    </li>
  );
};

export default SecondLevelItems;

SecondLevelItems.propTypes = {
  slug: PropTypes.string,
  parentSlug: PropTypes.string,
  categoryName: PropTypes.string
};
