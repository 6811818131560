const getStyles = (theme) => ({
  coverDesktop: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    // filter: 'brightness(.70)'
  },
  coverMobile: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    // filter: 'brightness(.70)'
  },
  // positionTitle: {
  //   left: '50%',
  //   transform: 'translate(-50%, 0%)',
  // },

  positionTitleBottom30: {
    position: 'absolute',
    bottom: '30%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '100%',
  },

  positionTitleBottom45: {
    position: 'absolute',
    bottom: '45%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: '100%',
  },
});

export default getStyles;

